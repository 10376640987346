
import { IQuestionnaireTemplate } from "../Types/Products/IQuestionnaireTemplate";

export const questionnaireTemplates: IQuestionnaireTemplate[] = [
    {
        "id": "3b4a6a68-4684-4f9d-9cd4-8b6d793e208e",
        "mandatedClientIds": ["d6ed2b60-9e45-47e5-a373-7383c7e075e9", "d77f6a33-f1c8-4974-bab4-1d5d0c2041c0"],
        "templateId": "5ad12cf5-2ee0-44fc-83e7-eca0ae09b16b",
        "title": "Inspection Framework",
        "overallGrade": "Red",
        "questionnaireSections": [
            {
                "id": "90d4e565-339c-43df-a1c2-4785b0936e44",
                "title": "Section 1: General Compliance",
                "description": "This section covers the general compliance requirements.",
                "active": true,
                "criteria": [
                    {
                        "id": "d9d9c3e2-76b4-4d36-a648-5fed282b8fc9",
                        "title": "Criterion 1: Policy Adherence",
                        "description": "Check if policies are being adhered to.",
                        "active": true,
                        "questions": [
                            {
                                "id": "ecd1fbeb-3021-4a2c-abf5-39f69eacd8d8",
                                "question": "How do you ensure a calm and orderly environment in all learning settings (classrooms, workshops, or workplaces), and how do staff respond to any disruptions?",
                                "answer": ""
                            },
                            {
                                "id": "1bb60ca8-8f53-4c0e-9f54-f814aef66646",
                                "question": "What strategies do you use to set and communicate clear behaviour expectations across all areas, including the workplace?",
                                "answer": ""
                            },
                            {
                                "id": "fcc23755-5cac-4200-9941-273123303f34",
                                "question": "Can you provide examples of how these are reinforced daily?",
                                "answer": ""
                            },
                            {
                                "id": "b4f97c6e-e4fa-4a07-a5ab-44bbcc769e8b",
                                "question": "How do you promote regular attendance and punctuality?",
                                "answer": ""
                            },
                            {
                                "id": "7dd0b8b6-64d8-49c1-8d0c-95ffb283e8d8",
                                "question": "What measures are in place to address and reduce absenteeism and lateness?",
                                "answer": ""
                            },
                            {
                                "id": "ba9c9902-45f5-4e6c-ab0d-e2f83e188f60",
                                "question": "What approaches do you employ to foster learner motivation and positive attitudes toward learning?",
                                "answer": ""
                            },
                            {
                                "id": "25a0738a-f842-4a62-bd50-4ea1b126f0de",
                                "question": "How do you track the impact of these attitudes on their progress?",
                                "answer": ""
                            },
                            {
                                "id": "4e3ce9f7-cab6-4b56-92a7-4c9f72b25f91",
                                "question": "How do you cultivate a positive and respectful culture where staff genuinely care about the learners?",
                                "answer": ""
                            },
                            {
                                "id": "d49e63b0-1f29-455b-913a-6629fc766352",
                                "question": "Can you describe some specific initiatives or daily practices that support this?",
                                "answer": ""
                            },
                            {
                                "id": "d80d6f5e-42ba-41f8-9278-a0c324bbbea7",
                                "question": "What steps are taken to ensure learners feel safe and protected from bullying, harassment, or discrimination?",
                                "answer": ""
                            },
                            {
                                "id": "394d52b6-6044-4fd5-ab54-82c8e4e7b57d",
                                "question": "How do you handle instances of peer-on-peer abuse, both online and offline?",
                                "answer": ""
                            }
                        ],
                        "grade": "Not Set",
                        "results": {
                            "id": "144d232e-9e8e-4de3-ae3c-060b3182b788",
                            "whatWeDo": "Conduct regular training sessions.",
                            "whatWeDoWell": "Training participation rate is 100%.",
                            "areasForImprovement": "Need to update training materials more frequently.",
                            "grade": "Green",
                            "tags": ["Compliance", "HR", "Training"]
                        }
                    },
                    {
                        "id": "bb974f9b-8bae-4f8b-877d-490123545e04",
                        "title": "Criterion 2: Regulatory Requirements",
                        "description": "Ensure all operations meet regulatory standards.",
                        "active": false,
                        "questions": [],
                        "grade": "Not Set",
                        "results": {
                            "id": "9add64de-f34b-47a1-96f5-1e451c6b168b",
                            "whatWeDo": "",
                            "whatWeDoWell": "",
                            "areasForImprovement": "",
                            "grade": "Red",
                            "tags": []
                        }
                    }
                ],
                "completionStatus": "In Progress"
            },
            {
                "id": "3c4b2f9b-a5ff-4780-b3c6-464b504db413",
                "title": "Section 2: Safety Protocols",
                "description": "Assessment of safety protocols in practice.",
                "active": true,
                "criteria": [
                    {
                        "id": "6c774f28-756b-4b0e-8e80-661f5e529e98",
                        "title": "Criterion 1: Emergency Preparedness",
                        "description": "Evaluate preparedness for emergencies.",
                        "active": true,
                        "questions": [
                            {
                                "id": "a42066bc-f8f3-4f3a-964e-d6a2b9683457",
                                "question": "Are emergency drills conducted regularly?",
                                "answer": "Yes, quarterly drills are conducted."
                            },
                            {
                                "id": "3ef7fb48-9b5b-4275-86b7-2aaf3db4ea14",
                                "question": "Are emergency contact numbers accessible to all employees?",
                                "answer": "Yes, posted in every department and the website."
                            }
                        ],
                        "grade": "Not Set",
                        "results": {
                            "id": "c86045f5-bd57-466a-b0b5-1df59b0a1486",
                            "whatWeDo": "Maintain an updated emergency contact list.",
                            "whatWeDoWell": "Quick response during drills.",
                            "areasForImprovement": "Need more frequent drill evaluations.",
                            "grade": "Red",
                            "tags": ["Safety", "Emergency", "Preparedness"]
                        }
                    }
                ],
                "completionStatus": "Complete"
            }
        ]
    },
    {
        id: "32235423525",
        mandatedClientIds: ["3455856789678", "1234567890123"],
        templateId: "2134134658768",
        title: "Inspection Framework",
        overallGrade: "Red",
        questionnaireSections: [
            {
                id: "564554",
                title: "Section 1: General Compliance",
                description: "This section covers the general compliance requirements.",
                active: true,
                criteria: [
                    {
                        id: "7654321",
                        title: "Criterion 1: Policy Adherence",
                        description: "Check if policies are being adhered to.",
                        active: true,
                        questions: [
                            {
                                id: "q1",
                                question: "How do you ensure a calm and orderly environment in all learning settings (classrooms, workshops, or workplaces), and how do staff respond to any disruptions?",
                                answer: ""
                            },
                            {
                                id: "q2",
                                question: "Is there a regular review process for compliance policies?",
                                answer: "Yes, policies are reviewed annually."
                            }
                        ],
                        grade: "Not Set",
                        results: {
                            id: "r1",
                            whatWeDo: "Conduct regular training sessions.",
                            whatWeDoWell: "Training participation rate is 100%.",
                            areasForImprovement: "Need to update training materials more frequently.",
                            grade: "Green",
                            tags: ["Compliance", "HR", "Training"]
                        }
                    },
                    {
                        id: "7654322",
                        title: "Criterion 2: Regulatory Requirements",
                        description: "Ensure all operations meet regulatory standards.",
                        active: false,
                        questions: [],
                        grade: "Not Set",
                        results: {
                            id: "r2",
                            whatWeDo: "",
                            whatWeDoWell: "",
                            areasForImprovement: "",
                            grade: "Red",
                            tags: []
                        }
                    }
                ],
                completionStatus: "In Progress"
            },
            {
                id: "7891011",
                title: "Section 2: Safety Protocols",
                description: "Assessment of safety protocols in practice.",
                active: true,
                criteria: [
                    {
                        id: "8765432",
                        title: "Criterion 1: Emergency Preparedness",
                        description: "Evaluate preparedness for emergencies.",
                        active: true,
                        questions: [
                            {
                                id: "q3",
                                question: "Are emergency drills conducted regularly?",
                                answer: "Yes, quarterly drills are conducted."
                            },
                            {
                                id: "q4",
                                question: "Are emergency contact numbers accessible to all employees?",
                                answer: "Yes, posted in every department and the website."
                            }
                        ],
                        grade: "Not Set",
                        results: {
                            id: "r3",
                            whatWeDo: "Maintain an updated emergency contact list.",
                            whatWeDoWell: "Quick response during drills.",
                            areasForImprovement: "Need more frequent drill evaluations.",
                            grade: "Red",
                            tags: ["Safety", "Emergency", "Preparedness"]
                        }
                    }
                ],
                completionStatus: "Complete"
            }
        ]
    }
];