import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SectionsDrawer.module.css';
import { SectionsDrawerItem } from './SectionsDrawerItem';
import { IProductQuestionnaire } from '../../Types/Products/IProductQuestionnaire';
import { IDrawerSection } from '../../Types/Drawer/ISectionsDrawerItem';
import { DrawerProvider, useDrawer } from './DrawerContext';

type SectionsDrawerProps = {
  sections: IDrawerSection[],
  basePath: string,
};

const SectionsDrawer: React.FC<SectionsDrawerProps> = ({ sections, basePath }) => {

  const { activeSection, setActiveSection } = useDrawer(); 

  useEffect(() => {
    console.log("section updated")
  }, [activeSection])

  return (
       <div>
        {sections.map((section, index) => (

          <div key={index} onClick={section === activeSection ? undefined : () => setActiveSection(section)}>
            <SectionsDrawerItem
            key={section.id}
            section={section}
            active={section === activeSection}
            basePath={basePath}
          />
          </div>
        
        ))}
    </div>
  );
};

export default SectionsDrawer;