import React from 'react'
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo'
import Card from '../../Evaluate/Card/Card'
import style from './CardCollection.module.css'
import { IQuestionnaireTemplate } from '../../../Types/Products/IQuestionnaireTemplate'
import TemplateCard from '../../Evaluate/Card/TemplateCard'

type TemplateCardCollectionProps = {
    templates : IQuestionnaireTemplate[]
}

const TemplateCardCollection = ({templates} : TemplateCardCollectionProps) => {

  return (
    <div className={style.cardsContainer}>
        {templates.map((item) => {
            return <TemplateCard key={item.id} url={`/portal/questionnaire-editor/${item.id}`} template={item}/>
        })}
    </div>
  )
}

export default TemplateCardCollection