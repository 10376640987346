import React, { useEffect, useState } from 'react'
//CSS
import styles from './Card.module.css';
//Components
import Button from '../../Utils/Button/Button';
import ProgressBar from '../../Utils/ProgressBar/ProgressBar';
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo';
import { Link } from 'react-router-dom';
import { IQuestionnaireTemplate } from '../../../Types/Products/IQuestionnaireTemplate';

type TemplateCardProps = {
    template: IQuestionnaireTemplate,
    url: string,
    editHandler?: () => void,
}


const TemplateCard = ( { template, url }: TemplateCardProps) => {

    const [progress, setProgress] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            //setProgress(percentage)
        }, 200)
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.status}>
                <div className={styles.statusLeft}>
                    <h2>{template.title}</h2>
                </div>
                <div className={styles.statusRight}>
                    <Button size="Small" colour="#52BFA2" textColour="#FFF">Not Started</Button>
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.infoLeft}>
                   
                </div>
                <div className={styles.infoRight}>
                   
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerLeft}>
                    <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">Archive</Button>
                </div>
                <div className={styles.footerRight}>
                    <Link to={url}>
                        <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">
                            {"Create With AI"}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default TemplateCard