import React from 'react'
//CSS
import styles from './QuestionnaireEditor.module.css';
import TemplateCardCollection from '../../../Components/Utils/LandingPages/TemplateCardCollection';
import { questionnaireTemplates } from '../../../Data/QuestionnaireTemplates';


const Portal = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Questionnaire Editor</h1>
      </div>
      <div className={styles.body}>
        <TemplateCardCollection templates={questionnaireTemplates}/>
      </div>
    </div>
  )
}

export default Portal