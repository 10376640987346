import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IQuestionnaireSection } from '../../Types/Products/IQuestionnaireSection'
import styles from './QuestionSectionCriteria.module.css';
import layout from '../../Modules/layout.module.css'
import { IQuestionnaireQuestion } from '../../Types/Products/IQuestionnaireQuestion';
import CompletionStatus from '../Utils/CompletionStatus/CompletionStatus';
import Paging from '../Utils/Paging/Paging';
import Button from '../Utils/Button/Button';
import GradeSelector from '../Utils/GradeSelector/GradeSelector';
import { IDropDownListItem } from '../../Types/DropDownList/IDropDownListItem';
import { IGrade } from '../../Types/IGrade';
import TagsBar from '../Utils/TagsBar/TagsBar';
import QuestionSectionTextArea from './QuestionSectionTextArea';
import QuestionSectionResults from './QuestionSectionResults';
import { UpdateQuestionnaireQuestion, UpdateQuestionnaireSectionStatus } from '../../API/Evaluate/Evaluate';
import useDebounce from '../../Hooks/Debounce/useDebounce';
import { ICriteriaItem } from '../../Types/Products/ICriteriaItemData';
import { IProductQuestionnaire } from '../../Types/Products/IProductQuestionnaire';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/Store';
import { selectCriteriaById, selectQuestionById, selectSectionById, setActiveCriteria, setActiveQuestionnaireSection, updateQuestionnaireGrade, updateQuestionnaireQuestion } from '../../Redux/Store/Slices/questionnaire';
import { validateLocaleAndSetLanguage } from 'typescript';

const pageSize = 3;

const QuestionSectionCriteria = () => {
  const { itemId, sectionId } = useParams<{ itemId?: string; sectionId?: string }>();

  const dispatch = useAppDispatch();
  const { activeCriteriaId, activeSectionId, questionnaire } = useAppSelector(state => state.questionnaire);

  const section = useAppSelector(selectSectionById(activeSectionId));
  const criteria = useAppSelector(selectCriteriaById(activeSectionId, activeCriteriaId));

  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isViewingResults, setIsViewingResults] = useState<boolean>(false);

  useEffect(() => {
    const completed = criteria?.questions.every(item => item.answer.trim().length > 0) ?? false;
    setIsViewingResults(completed)
  }, [])

  useEffect(() => {
    if (sectionId && sectionId !== activeSectionId) {
      dispatch(setActiveQuestionnaireSection({ sectionId }));
    }
  }, [dispatch, sectionId, activeSectionId]);

  useEffect(() => {

    const init = async () => {

      if (itemId && itemId !== activeCriteriaId) {
        await dispatch(setActiveCriteria({ itemId: itemId }));
      }

      if(criteria?.results){
        const {whatWeDo, whatWeDoWell, areasForImprovement} = criteria.results;
        setIsViewingResults((whatWeDo + whatWeDoWell + areasForImprovement).trim() !== "")
      }
    }
    init();
    
  }, [dispatch, itemId, activeCriteriaId]);

  useEffect(() => {
    const pages = Math.ceil(7 / pageSize);
    setPageCount(pages);
  }, [criteria]);

  const nextPageClickHandler = () => {
    if(isViewingResults){
      setIsViewingResults(false)
    }
    else if (activePage === pageCount) {
      setIsViewingResults(true)
      setActivePage(1)
    }
    else {
      setActivePage(activePage + 1)
    }
  }

  if(criteria && section){

    return (

      <div className={layout.container}>
    
        <div className={layout.column2}>
            {!isViewingResults ? 
            <>
              <section className={layout.column}>

                <div className={layout.row}>
                  <div className={layout.row2}>
                    <h2>{section.title}</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 48 48" width="25"><path d="M0 0h48v48h-48z" fill="none" /><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" /></svg>
                  </div>
                  <div className={`${layout.row2} ${layout.rowReverse}`}>
                    <div>
                      <CompletionStatus status={section.completionStatus ?? "Incomplete"} />
                    </div>
                    <h4>
                      Page {activePage} of {pageCount}
                    </h4>
                  </div>
                </div>
                <div className={layout.rowSpan40}>
                  <p className={styles.description}>{criteria?.title}</p>
                </div>
        
              </section>
              <section className={layout.column2}>

                {criteria?.questions.slice(activePage * pageSize - pageSize, activePage * pageSize).map((item) => {
                  return <div key={criteria.questions.indexOf(item)} className={layout.column1}>

                    <div className={layout.rowHalf}>
                      <h5>{criteria.questions.indexOf(item) + 1}.</h5>
                      <h5>{item.question}</h5>
                    </div>

                    <QuestionSectionTextArea onChangeHandler={(x) => dispatch(updateQuestionnaireQuestion({...item, answer: x}))} className={styles.answer} placeholder='Answer...' value={item.answer} />
                  </div>
                })}

                </section>
                <div className={`${layout.row} ${layout.rowReverse}`}>
                  <Button colour={"#24394F"} clickHandler={nextPageClickHandler}>{activePage === pageCount ? "Generate Assessment" : "Next"}
                    {activePage === pageCount && <img src='/img/sparkle.svg' />}
                  </Button>
                </div>
                <Paging activePage={activePage} pageCount={pageCount} pageChanged={setActivePage} />
              </>
            : 
              <div className={layout.column2}>
                <section className={layout.column} style={{gap: 0}}>

                  <div className={layout.row}>
                    <div className={layout.row2}>
                      <h2>{section.title}</h2>
                      <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 48 48" width="25"><path d="M0 0h48v48h-48z" fill="none" /><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" /></svg>
                    </div>
                    <div className={`${layout.row2} ${layout.rowReverse}`}>
                      <div>
                        <CompletionStatus status={section.completionStatus ?? "Incomplete"} />
                      </div>
                    </div>
                    
                  </div>
                  {/*<div className={`${layout.row2} ${layout.rowReverse}`}>
                    <GradeSelector readOnly={true} onChange={(grade) => dispatch(updateQuestionnaireGrade(grade.text as IGrade))} grade={questionnaire!.overallGrade}/>
                  </div>*/}

                </section>

                <section className={layout.column2}>
                  {
                  <QuestionSectionResults/>
                  }
                  </section>
               
                <div className={layout.marginTop2}>
                  <TagsBar tags={criteria?.results?.tags ?? ["no tags"]} />
                </div>

                <div className={`${layout.row} ${layout.rowReverse}`}>
                  <Button colour={"#24394F"} clickHandler={nextPageClickHandler}>Back to questionnaire
                  </Button>
                </div>
              </div>
            }
        </div>
        
      </div>
    )
  }
  else{

    return <></>
  }
  
}

export default QuestionSectionCriteria